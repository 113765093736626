
.audioAmpChangerSection {
  padding-bottom: 1rem;
}

.audioAmpChangerListItem {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  /* border: 1px solid gray; */
  box-shadow: var(--shadow-small);
}

.audioAmpChangerListTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.audioAmpChangerListControl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.audioAmpChangerListControlButton {
  padding: 0.5rem 0.75rem;
  box-shadow: var(--shadow-small);
}

.audioAmpChangerChangeButton {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid gray;
  box-shadow: var(--shadow-small);
}

.audioAmpChangerChangeButton:disabled {
  color: gray;
}

.audioAmpChangerChangeButton:hover {
  background-color: var(--color-gray-100);
}

.audioAmpChangerAudioEl {
  max-width: 100%;
}

.audioAmpChangerChangeFactorRow {
  padding-bottom: 0.5rem;
}

.audioAmpChangerSliderRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* padding-bottom: 1rem; */
}

.audioAmpChangerSelectButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
