
:focus:not(:focus-visible) {
  outline: none;
}

.btn-reset {
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
}

.SlicerContainer {
  box-sizing: border-box;
  position: relative;
  /* width: 1080px; */
  margin: 0 auto;
  padding: 20px;
}

.app-title {
  position: absolute;
  text-align: center;
  color: #ccc;
  font-size: 40px;
  left: 0;
  right: 0;
  top: -135px;
}

.player {
  position: relative;
  margin: 0 auto;
  height: 160px;
  display: block;
  /* box-shadow: 0 0 30px rgba(0,0,0,.1); */

  .wave-canvas {
    position: absolute;
  }
}

.player-landing {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ddd;
  font-size: 24px;
}

.dragger {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #999;
  cursor: col-resize;

  &::after {
    content: '';
    position: absolute;
    left: -2px;
    right: -2px;
    top: 0;
    bottom: 0;
  }

  &:hover {
    background: #333;
  }
}

.drag-current {
  background: #0cf;
}

.landing {
  padding-bottom: 100px;

  /* h2 {
    padding: 0;
    margin: 0;
    text-align: center;
    color: #ccc;
    font-size: 40px;
    margin-bottom: 40px;
  } */
}

.controllers {
  display: flex;
  margin-top: 10px;

  .seconds {
    font-size: 12px;
    line-height: 36px;
    margin-left: 10px;
    display: inline-block;
    overflow: hidden;
    color: #aaa;
  }
}

.file {
  input {
    visibility: hidden;
    position: absolute;
  }
}

.file-main {
  display: block;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  color: #999;
  transition: 0.3s;
  background: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, .1);

  .icon {
    margin: -1px 5px 0;
    font-size: 24px;
  }

  &:hover {
    color: #6ac;
    border-color: #6ac;
  }
}

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon.__icon-spin {
  animation: spin infinite 1s linear;
}

.ctrl-item {
  &:extend(.btn-reset);

  display: inline-block;
  font-size: 16px;
  text-align: center;
  color: #999;
  padding: 10px;
  margin-right: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 20px rgba(0,0,0,0.16);
  }

  .icon {
    display: block;
  }
}

.dropdown {
  display: inline-block;
  position: relative;

  .list-wrap {
    position: relative;
  }

  .list {
    position: absolute;
    width: 60px;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition-duration: .3s;
    transition-property: opacity, visibility;
    list-style: none;
    background: #fff;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    padding: 0;
    margin: 0;
    z-index: 10;

    button {
      &:extend(.btn-reset);

      display: block;
      width: 100%;
      text-align: center;
      padding: 5px 0;
      color: inherit;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background: #333;
        color: #fff;
      }
    }
  }

  &:hover .list {
    opacity: 1;
    visibility: visible;
  }
}

.clipper {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* .cursor-current {
  @cursorColor: #0cf;

  position: absolute;
  font-size: 12px;
  top: -22px;
  padding: 1px 3px;
  text-align: center;
  color: #fff;
  transform: translate(-50%) scale(0.8);
  background: @cursorColor;

  .num {
    font-family: monospace;
  }

  &::after {
    content: '';
    position: absolute;
    border: 5px solid transparent;
    border-top-color: #0cf;
    bottom: -9px;
    left: 50%;
    margin-left: -5px;
  }
} */

.audioSlicerSection {
  padding-bottom: 1rem;
}

.audioSlicerSliceButton {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid gray;
  box-shadow: var(--shadow-small);
}

.audioSlicerSliceButton:disabled {
  color: gray;
}

.audioSlicerSliceButton:hover {
  background-color: var(--color-gray-100);
}

.audioSlicerAudioEl {
  max-width: 100%;
}

.audioSlicerSelectButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
