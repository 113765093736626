
.voiceFileControl {
  margin: 0 auto;
  max-width: 40rem;
  /* padding: 2rem; */
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.voiceFileControlButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
