
.videoToAudio {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 0.5rem;
}

.videoToAudioSection {
  padding-bottom: 1rem;
}

.videoToAudioTitle {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.videoToAudioAudio {
  max-width: 100%;
}


.videoToAudioButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

