
.voiceFileUploadSection {
  padding-bottom: 1rem;
}

.voiceFileUploadTitle {
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

