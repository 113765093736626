
.audioMixerSection {
  padding-bottom: 1rem;
}

.audioMixerListItem {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  /* border: 1px solid gray; */
  box-shadow: var(--shadow-small);
}

.audioMixerListTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.audioMixerListControl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.audioMixerListControlButton {
  padding: 0.5rem 0.75rem;
  box-shadow: var(--shadow-small);
}

.audioMixerCombineButton {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid gray;
  box-shadow: var(--shadow-small);
}

.audioMixerCombineButton:disabled {
  color: gray;
}

.audioMixerCombineButton:hover {
  background-color: var(--color-gray-100);
}

.audioMixerAudioEl {
  max-width: 100%;
}

.audioMixerSelectButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.audioMixerBlankDurationRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}