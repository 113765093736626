
.audioCombinerSection {
  padding-bottom: 1rem;
}

.audioCombinerListItem {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  /* border: 1px solid gray; */
  box-shadow: var(--shadow-small);
}

.audioCombinerListTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.audioCombinerListControl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.audioCombinerListControlButton {
  padding: 0.5rem 0.75rem;
  box-shadow: var(--shadow-small);
}

.audioCombinerCombineButton {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid gray;
  box-shadow: var(--shadow-small);
}

.audioCombinerCombineButton:disabled {
  color: gray;
}

.audioCombinerCombineButton:hover {
  background-color: var(--color-gray-100);
}

.audioCombinerAudioEl {
  max-width: 100%;
}

.audioCombinerSelectButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}