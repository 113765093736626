.voiceGenerator2 {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 0.5rem;
}

.voiceGenerator2Section {
  padding-bottom: 1rem;
}

.voiceGenerator2Title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.voiceGenerator2TitleSub {
  text-align: center;
  /* font-weight: bold; */
}

.voiceGenerator2SettingRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.voiceGenerator2SettingSpeed {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.voiceGenerator2SettingSpeedInput {
  width: 15rem;
  max-width: 70%;
}



.voiceGenerator2SpeakerInput {
  width: 5rem;
  border: 1px solid gray;
  border-radius: 2px;
}

.voiceGenerator2TextInput {
  width: 100%; 
  max-width: 40rem;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 0.1rem;
}

.voiceGenerator2TextInputLabels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.voiceGenerator2OutputCard {
  padding: 1rem;
  /* border: 1px solid; */
  box-shadow: var(--shadow-medium);
  border-radius: 6px;
}

.voiceGenerator2Audio {
  max-width: 100%;
}

.voiceGenerator2Select {
  padding: 0.5rem
}