
.audioConvertToMP3Section {
  padding-bottom: 1rem;
}

.audioConvertToMP3ListItem {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  /* border: 1px solid gray; */
  box-shadow: var(--shadow-small);
}

.audioConvertToMP3ListTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.audioConvertToMP3ListControl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.audioConvertToMP3ListControlButton {
  padding: 0.5rem 0.75rem;
  box-shadow: var(--shadow-small);
}

.audioConvertToMP3ConvertButton {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid gray;
  box-shadow: var(--shadow-small);
}

.audioConvertToMP3ConvertButton:disabled {
  color: gray;
}

.audioConvertToMP3ConvertButton:hover {
  background-color: var(--color-gray-100);
}

.audioConvertToMP3AudioEl {
  max-width: 100%;
}

.audioConvertToMP3SelectButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}