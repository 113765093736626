
.language-container {
  display: flex;
  gap: 20px;
}

.textbox-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 800px;
}

.textbox-container>textarea, .language-selector {
  width: 50%;
}

.language-selector>select {
  width: 150px;
}

.progress-container {
  position: relative;
  font-size: 14px;
  color: white;
  background-color: #e9ecef;
  border: solid 1px;
  border-radius: 8px;
  text-align: left;
  overflow: hidden;
}

.progress-bar {
  padding: 0 4px;
  z-index: 0;
  top: 0;
  width: 1%;
  height: 100%;
  overflow: hidden;
  background-color: #007bff;
  white-space: nowrap;
}

.progress-text {
  z-index: 2;
}

.selector-container {
  display: flex;
  gap: 20px;
}

.progress-bars-container {
  padding: 8px;
  height: 140px;
}

.container {
  margin: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answerFinder {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 0.5rem;
}
.answerFinderSection {
  padding-bottom: 1rem;
}

.answerFinderTitle {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.answerFinderContextContainer {
  width: 100%;
}

.answerFinderContext {
  width: 100%;
  padding: 0.5rem;
  border: 0.1px solid gray;
  border-radius: 4px;
}

.answerFinderContextLabels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.answerFinderQuestionContainer {
  width: 100%;
}

.answerFinderQuestion {
  width: 100%;
  padding: 0.5rem;
  border: 0.1px solid gray;
  border-radius: 4px;
}

.answerFinderButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

