
.transcribeFileControl {
  margin: 0 auto;
  max-width: 40rem;
  padding: 2rem;
  padding-bottom: 2rem;
}

.transcribeFileControlButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
