
.aboutPageTitle {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.aboutPageTopImages {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.aboutPageTopImage {
  height: 250px;
  width: 250px;
  max-width: 100%;
}